import styles from "../../../styles/voltmesh-features.module.scss"
import cblockStyles from "../../../styles/commons/cblock.module.scss"
import React, { useState } from "react"
import Layout from "../../../components/layout/layout"
import StartDemo from "../../../components/start-demo/start-demo"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../../../components/seo"
import withAppContext from "../../../contexts/app.context"
import { ENV_CONSOLE_BASE_URL } from "../../../utils/secrets"
import UseCaseBenefits from "../../../components/use-case-benefit/use-case-benefits"
import TopFeatures from "../../../components/top-features/top-features"
import FaqSection from "../../../components/faq-section/faq-section"
import FsLightbox from "fslightbox-react"
import PlaySvg from "../../../components/svg/play"
import ExternalLinkSvg from "../../../components/svg/external-link"

const VoltMeshShapeDeviceIdPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "products__device-id" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const lowFrictionVideo = "https://player.vimeo.com/video/544679093?autoplay=1"
  const dataVisibilityVideo =
    "https://player.vimeo.com/video/544678818?autoplay=1"

  const [videoLink, setVideoLink] = useState(lowFrictionVideo)
  const [isVideoOpened, setVideoOpened] = useState(false)

  const openVideo = link => () => {
    setVideoLink(link)
    setVideoOpened(!isVideoOpened)
  }

  const questions = [
    {
      title:
        "How is Shape Device ID different from traditional device identifiers such as cookies?​",
      expanded: false,
      answer: `
      Shape Device ID differs from cookies in three main domains:
      <ul>
        <li>Shape Device ID leverages advanced signals to assign a unique identifier to each device and leverages ML based linking algorithm to minimize assigning multiple identifiers to the same device. Traditional solutions on the other hand are more volatile and can assign multiple identifiers for the same device. </li>
        <li>Shape Device ID deployment is simple and easy and is not as resource intensive as traditional solutions. Shape Device ID can be easily integrated with existing security and fraud models, allowing to correlate the identifier with existing data to identify anomalies​.</li>
        <li>Shape Device ID alleviates all privacy concerns by calculating first party identifiers based on non-PII data where are traditional solutions track customers across various applications raising privacy concerns.</li>
      </ul>
      `,
    },
    {
      title: "How can I get started with Shape Device ID?",
      expanded: false,
      answer: `
      <p>There are many easy ways to add a script tag. F5 BIG-IP customers may use a freely provided iApp. NGINX Controller customers may modify their config files to inject the JS. Shape Enterprise Defense and Silverline customers may reach out to their managed service team to add the tag through configuration. Users of content management systems or tag managers may use these tools to add the tag. And that’s it. You’re up and running.​</p><p>With the tag inline, you gain the benefits by consuming the identifier via a cookie. You can integrate the identifier into your application’s real-time behavior or log it to your SIEM for richer analysis.​</p>
      `,
    },
  ]

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/products/shape/device-id"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />

      {/* Hero Block */}
      <section className={`${styles.header} ${styles.header__deviceid}`}>
        <div className={styles.header__content}>
          <div className="container volterra-container">
            <div className="row">
              <div className={`col-lg-7`}>
                <h1
                  className={`${styles.header__title} ${styles.header__title_Highlighted}`}>
                  Shape Device ID
                </h1>
                <h2 className={styles.header__title}>
                  Easily identify your new and returning users
                </h2>
                <p className={`mb-0 ${styles.header__description}`}>
                  Shape Device ID delivers a real-time unique identifier for
                  each device visiting web and mobile web sites to help fraud,
                  security, operations and marketing teams better serve and
                  protect their customers.
                </p>
                <a
                  aria-label="Start for Free"
                  href={`${ENV_CONSOLE_BASE_URL}/signup/start`}
                  className={"btn btn-primary " + styles.header__btn}>
                  Start for Free
                </a>
              </div>
            </div>
          </div>
          <div className={styles.header__imgwrapper}></div>
        </div>
      </section>

      {/* Main Features */}
      <UseCaseBenefits
        props={{
          title: "Features of Shape Device ID",
          imgUrl: "/img/products/",
          content: {
            benefits: [
              {
                iconName: "shield-alt2-icon.svg",
                title: "Improved device visibility",
                description: `Monitor device behavior across different applications with metrics such as number of devices, age of devices, geo-location, session length of the device etc.`,
              },
              {
                iconName: "puzzle-icon.svg",
                title: "Identify new and returning devices​",
                description: `Leverage accurate device identifier to identify new and returning devices to identify any unexpected variations on your applications. `,
              },
              {
                iconName: "compliance-icon.svg",
                title: "Free bot level assessment​",
                description: `Get visibility into how much application traffic is contributed by bots and automation based on behavioral differences between human and bots.`,
              },
              {
                iconName: "operations-alt2-icon.svg",
                title: "Augment existing internal tools​",
                description: `Enhance your internal fraud and security analytics derived from internal tools by incorporating accurate device identifiers.`,
              },
            ],
          },
        }}
      />

      {/* Top Features */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Benefits of Shape Device ID
            </h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <TopFeatures
              props={{
                fullWidthMobileImages: true,
                features: [
                  {
                    title: "Increased efficacy through Advanced ML",
                    desc:
                      "Shape Device ID utilizes advanced signal collection and proven machine learning algorithms to assign a unique identifier to each device visiting your site as opposed to traditional solutions such as cookies which are volatile and provide limited visibility into new and returning users.",
                    imgUrl:
                      "/img/products/device-id-advanced-ml-reliability.svg",
                    imgAlt:
                      "Diagram showing Shape Device ID creating digital fingerprint from non-personal user data",
                  },
                  {
                    title: "Easily identify anomalies",
                    desc:
                      "Shape Device ID can be easily integrated with existing fraud and security models, allowing to simplify the identifier with existing data to identify anomalies.",
                    imgUrl: "/img/products/device-id-identify-anomalies.svg",
                    imgAlt: "Image showing a graph with visible anomaly",
                  },
                  {
                    title: "Simplified deployments with no privacy concerns",
                    desc:
                      "Shape Device ID can be deployed in a very short amount of time with existing infrastructure unlike traditional solutions that are very resource intensive. Unlike traditioinal solutions which raise privacy concerns for tracking customers across different applications, Shape Device ID alleviates privacy concerns by calculating first party identifiers based on non-PII data.",
                    imgUrl:
                      "/img/products/device-id-simplified-deployments.svg",
                    imgAlt:
                      "Diagram showing Device ID JS tag being added to the website",
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>

      {/* How Shape Device ID Works */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              How Shape Device ID Works
            </h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 mt-4 mt-lg-n3">
              <img
                className="img-fluid"
                src="/img/products/device-id-how-it-works.svg"
                alt="A graph showing how Device ID created digital fingerprints without using user's personal data"></img>
            </div>
            <div className="col-lg-6">
              <div className={styles.textblock}>
                <p className={cblockStyles.cblock__intro}>
                  When each user visits your website, Shape Device ID leverages
                  JavaScript to collect information about the browser, device’s
                  OS, hardware, and network configuration. These attributes feed
                  into Shape's industry recognized AI and Machine learning
                  powered services. The data is processed in real time, and a
                  unique identifier is assigned to the device.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.videos} ${cblockStyles.cblock}`}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2>Use Case Videos</h2>
          </div>
        </div>
        <div
          className={`container volterra-container ${styles.videos__container}`}>
          <div className={styles.video}>
            <div
              onClick={openVideo(lowFrictionVideo)}
              aria-label="Open Low friction deployments video"
              className={`${styles.video__preview} ${styles.video__preview3}`}>
              <PlaySvg className={styles.video__previewPlay} />
            </div>
            <div className={styles.video__body}>
              <h5 className={styles.video__title}>Low friction deployments</h5>
              <p className={styles.video__description}>
                An overview of how Shape Device ID can be enabled in a very
                small amount of time
              </p>
              <a
                className={styles.video__link}
                href="/docs/quick-start/device-id-qs"
                target="_blank">
                Demo Documentation
                <span className={styles.video__linkIcon}>
                  <ExternalLinkSvg />
                </span>
              </a>
            </div>
          </div>
          <div className={styles.video}>
            <div
              onClick={openVideo(dataVisibilityVideo)}
              aria-label="Open Enhanced Data Visibility​"
              className={`${styles.video__preview} ${styles.video__preview4}`}>
              <PlaySvg className={styles.video__previewPlay} />
            </div>
            <div className={styles.video__body}>
              <h5 className={styles.video__title}>Enhanced Data Visibility​</h5>
              <p className={styles.video__description}>
                An overview of how Shape Device ID can provide high fidelity
                visibility to detect anomalies
              </p>
              <a
                className={styles.video__link}
                href="/docs/how-to/shape/device-id#view-data-in-device-id-dashboard"
                target="_blank">
                Demo Documentation
                <span className={styles.video__linkIcon}>
                  <ExternalLinkSvg />
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.questions}>
        <div className={`container ${styles.questions__container}`}>
          <FaqSection props={{ questions }} />

          <div className={`mt-4 ${styles.faq__foot}`}>
            <p>
              Subscribe to the free Shape Device ID service via the VoltConsole
              portal, talk to your F5 account rep, or email{" "}
              <a href="mailto:deviceidplus@f5.com">deviceidplus@f5.com</a> to
              get started with Shape Device ID today.
            </p>
            <p>
              Have more questions?{" "}
              <a href="/docs/faqs/shape-faq#device-id" target="_blank">
                Visit our FAQ page <ExternalLinkSvg />
              </a>
            </p>
          </div>
        </div>
      </section>

      <StartDemo
        props={{
          benefits: [
            "User identification",
            "Application Security",
            "Anomaly detection",
            "Fraud mitigation",
          ],
        }}
      />

      <FsLightbox
        toggler={isVideoOpened}
        sources={[
          <iframe
            src={videoLink}
            width="1920px"
            height="1080px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
      />
    </Layout>
  )
}

export default withAppContext(VoltMeshShapeDeviceIdPage)
